<template>
  <div class="system" v-if="license">
    <v-tabs
      v-model="tab"
      :color="whiteLabel.colors.primary"
      icons-and-text
      show-arrows
      center-active
      fixed-tabs
      align-with-title
    >
      <v-tab key="licenseTab" @click="activeTab('licenseTab')">
        Licencia
        <v-icon>mdi-star</v-icon>
      </v-tab>

      <v-tab
        key="restartTab"
        v-if="license.gate"
        @click="activeTab('restartTab')"
      >
        Auto-Reinicio
        <v-icon>mdi-power</v-icon>
      </v-tab>

      <v-tab
        key="accesoriesTab"
        @click="activeTab('accesoriesTab')"
        v-if="
          (license.gate || license.virtual) &&
          (license.doors > 0 || license.rfid)
        "
      >
        Accesorios
        <v-icon>mdi-hammer-screwdriver</v-icon>
      </v-tab>

      <v-tab key="qrTab" v-if="license.virtual" @click="activeTab('qrTab')">
        QR
        <v-icon>mdi-qrcode</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="licenseTab">
        <div class="text-center mt-5 mb-4 d-flex justify-center">
          <div>
            <v-btn color="red" dark rounded @click.prevent="">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
            <div class="caption font-weight-light">Reseteo a fábrica</div>
          </div>
        </div>

        <v-container>
          <v-card class="mx-auto" v-if="license">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-license</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Licencia
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-identifier</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle
                >IMEI {{ user.data.building }}</v-list-item-subtitle
              >
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar-today</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle
                >Activación {{ license.activation | dateFormat("DD/MM/YYYY") }}
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>fa-th</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                {{ availableUnits }} de {{ license.maxUnits }} unidades
                disponibles
                <v-progress-linear
                  :color="whiteLabel.colors.primary"
                  rounded
                  v-model="progressUnits"
                ></v-progress-linear>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item v-if="license.doors">
              <v-list-item-icon>
                <v-icon>mdi-door-closed-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle
                >Apertura remota para
                {{ license.doors }} acceso/s</v-list-item-subtitle
              >
            </v-list-item>

            <v-list-item v-if="license.rfid">
              <v-list-item-icon>
                <v-icon>mdi-remote</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle
                >Control de acceso por proximidad</v-list-item-subtitle
              >
            </v-list-item>

            <v-list-item v-if="license.video">
              <v-list-item-icon>
                <v-icon>mdi-video-wireless</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>Video llamada</v-list-item-subtitle>
            </v-list-item>

            <v-list-item v-if="license.virtual">
              <v-list-item-icon>
                <v-icon>mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle v-if="license.lite"
                >QR Virtual Lite</v-list-item-subtitle
              >
              <v-list-item-subtitle v-else>QR Virtual</v-list-item-subtitle>
            </v-list-item>

            <v-list-item v-if="license.audio">
              <v-list-item-icon>
                <v-icon>mdi-speaker</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>Audio boost</v-list-item-subtitle>
            </v-list-item>

            <v-list-item v-if="license.demo">
              <v-list-item-icon>
                <v-icon>mdi-test-tube</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>Modo demo</v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-container>

        <v-container>
          <v-card class="mx-auto" v-if="license">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-seal</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Distribuidor
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                {{ whiteLabel.labels.brand }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-web</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                {{ whiteLabel.support.website }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle>
                {{ whiteLabel.support.email }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="restartTab" v-if="license.gate">
        <v-container>
          <v-card class="mx-auto" v-if="license">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-restart</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  Reinicio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-icon v-if="restartEnabled">
                <v-icon>mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle v-if="restartEnabled">
                Reinicio automático activado, todos los dias a las
                {{ restartTime }} hs.
              </v-list-item-subtitle>
              <v-list-item-icon v-if="!restartEnabled">
                <v-icon>mdi-window-close</v-icon>
              </v-list-item-icon>
              <v-list-item-subtitle v-if="!restartEnabled">
                Reinicio automático desactivado.
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-switch
                  v-model="restartEnabled"
                  color="success"
                  inset
                  class="v-input--reverse v-input--expand"
                  @change="saveRestart()"
                >
                  <template #label> Reinicio automático </template>
                </v-switch>

                <v-time-picker
                  v-if="restartEnabled"
                  v-model="restartTime"
                  ampm-in-title
                  :landscape="$vuetify.breakpoint.smAndUp"
                  format="24hr"
                  scrollable
                  :color="whiteLabel.colors.primary"
                  :value="restartTime"
                  @change="saveRestart()"
                ></v-time-picker>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item
        key="accesoriesTab"
        v-if="
          (license.gate || license.virtual) &&
          (license.doors > 0 || license.rfid)
        "
      >
        <v-container>
          <v-card class="mx-auto" v-if="license.doors">
            <v-form ref="form">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-door-closed-lock</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    Apertura de accesos
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list-item v-for="(door, index) in doors" :key="`door${index}`">
                <v-list-item-content v-if="license.doors > index">
                  <v-row>
                    <v-col cols="12" sm="1" class="text-center">
                      <v-icon class="pt-5"
                        >mdi-numeric-{{ index + 1 }}-circle</v-icon
                      >
                    </v-col>
                    <v-col cols="8" sm="3">
                      <v-text-field
                        label="Nombre"
                        v-model="door.name"
                        type="text"
                        :rules="nameRules"
                        counter
                        :color="whiteLabel.colors.primary"
                        @input="inputChanged"
                        @blur="saveDoors()"
                        class="pt-4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="2" v-if="license.gate">
                      <v-select
                        v-model="door.number"
                        :items="dtmfs"
                        label="Código"
                        item-text="number"
                        item-value="number"
                        :color="whiteLabel.colors.primary"
                        class="pt-4"
                        @change="saveDoors()"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-slider
                        v-model="door.time"
                        min="1"
                        max="10"
                        :color="whiteLabel.colors.primary"
                        :track-color="whiteLabel.colors.secondary"
                        hint="Tiempo de apertura (en segundos)"
                        thumb-label="always"
                        label=""
                        persistent-hint
                        class="pt-3 pt-sm-9"
                        @end="saveDoors()"
                      ></v-slider>
                    </v-col>
                    <v-col cols="8" sm="2" v-if="mode_wifi">
                      <v-text-field
                        label="Dispositivo WIFI"
                        v-model="door.device"
                        type="text"
                        hint="ID de Dispositivo WIFI"
                        counter
                        :color="whiteLabel.colors.primary"
                        @input="inputChanged"
                        @blur="saveDoors()"
                        class="pt-4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" sm="2" v-if="mode_wifi">
                      <v-select
                        v-model="door.nc"
                        :items="[{value: false, text: 'NA'}, {value: true, text: 'NC'}]"
                        label="Relé"
                        hint="Normal Abierto / Normal Cerrado"
                        persistent-hint
                        :color="whiteLabel.colors.primary"
                        class="pt-4"
                        @change="saveDoors()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-form>
          </v-card>
        </v-container>
      </v-tab-item>

      <v-tab-item key="qrTab" v-if="license.virtual">
        <v-container>
          <v-card class="mx-auto">
            <v-form ref="form">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-qrcode-scan</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="headline">
                    Generación y descarga de Código QR
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col cols="4">
                    Patrón de color
                    <v-color-picker
                      v-model="colorLight"
                      hide-canvas
                      hide-sliders
                      hide-inputs
                      hide-mode-switch
                      show-swatches
                      :swatches="swatches"
                      swatches-max-height="400"
                      @input="qrGenerate"
                      class="mb-6"
                    ></v-color-picker>
                    Tamaño de los puntos
                    <v-slider
                      v-model="dotScale"
                      @change="qrGenerate"
                      max="10"
                      min="6"
                      class="mb-0"
                      :color="whiteLabel.colors.primary"
                      :track-color="whiteLabel.colors.secondary"
                    ></v-slider>
                    <v-checkbox
                      v-model="includeBuilding"
                      label="Incluir Nombre o Dirección de la vivienda"
                      @change="qrGenerate"
                      class="mt-0 mb-0"
                      :color="whiteLabel.colors.primary"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="8">
                    <div ref="qrcode" style="min-height: 550px"></div>
                    <v-btn
                      :color="whiteLabel.colors.primary"
                      class="mt-2"
                      @click="qrDownload()"
                      min-width="100%"
                      dark
                    >
                      Descargar QR
                      <v-icon right dark>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import { db } from "@/fb";
import { mapGetters } from "vuex";
import * as QRCode from "easyqrcodejs";

export default {
  data: () => ({
    tab: 0,
    totalUnits: 0,
    doors: [
      { name: "", number: null, time: null, device: "" },
      { name: "", number: null, time: null, device: "" },
      { name: "", number: null, time: null, device: "" },
    ],
    mode_wifi: false,
    dtmfs: [
      { number: 0 },
      { number: 1 },
      { number: 2 },
      { number: 3 },
      { number: 4 },
      { number: 5 },
      { number: 6 },
      { number: 7 },
      { number: 8 },
      { number: 9 },
    ],
    restartEnabled: false,
    restartTime: null,
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    isValid: true,
    nameRules: [
      (v) => v.length <= 15 || "Largo máximo es 15 caracteres",
      (v) => !!v || "Nombre de puerta es obligatorio",
    ],
    qr: null,
    colorLight: "#FFFFFF",
    dotScale: 10,
    swatches: [],
    vid: null,
    buildingName: "",
    buildingAddress: "",
    includeBuilding: false,
    text: null,
    value: null,
  }),

  created() {},

  mounted() {
    this.getSettingsInfo();
    this.snackbar.visible = true;
    this.snackbar.text = "Cargando datos ...";
    this.snackbar.color = "success";

    this.colorPallete();
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
    availableUnits: function () {
      return this.license.maxUnits - this.totalUnits;
    },
    progressUnits: function () {
      return (this.totalUnits * 100) / this.license.maxUnits;
    },
  },

  methods: {
    async getSettingsInfo() {
      db.doc("buildings/" + this.user.data.building)
        .get()
        .then((building) => {
          this.totalUnits = building.data().total_units;
          this.doors[0].name = building.data().settings.door1_name
            ? building.data().settings.door1_name
            : "";
          this.doors[1].name = building.data().settings.door2_name
            ? building.data().settings.door2_name
            : "";
          this.doors[2].name = building.data().settings.door3_name
            ? building.data().settings.door3_name
            : "";
          this.doors[0].number = building.data().settings.door1_number
            ? building.data().settings.door1_number
            : 0;
          this.doors[1].number = building.data().settings.door2_number
            ? building.data().settings.door2_number
            : 0;
          this.doors[2].number = building.data().settings.door3_number
            ? building.data().settings.door3_number
            : 0;
          this.doors[0].time = building.data().settings.door1_time
            ? building.data().settings.door1_time
            : 0;
          this.doors[1].time = building.data().settings.door2_time
            ? building.data().settings.door2_time
            : 0;
          this.doors[2].time = building.data().settings.door3_time
            ? building.data().settings.door3_time
            : 0;
          this.doors[0].device = building.data().settings.door1_dvcid
            ? building.data().settings.door1_dvcid
            : "";
          this.doors[1].device = building.data().settings.door2_dvcid
            ? building.data().settings.door2_dvcid
            : "";
          this.doors[2].device = building.data().settings.door3_dvcid
            ? building.data().settings.door3_dvcid
            : "";
          this.doors[0].nc = building.data().settings.door1_nc
            ? building.data().settings.door1_nc
            : false;
          this.doors[1].nc = building.data().settings.door2_nc
            ? building.data().settings.door2_nc
            : false;
          this.doors[2].nc = building.data().settings.door3_nc
            ? building.data().settings.door3_nc
            : false;
          // WIFI / BT mode.
          this.mode_wifi = building.data().settings.mode_wifi
            ? building.data().settings.mode_wifi
            : false;
          // Restart.
          this.restartEnabled = building.data().settings.restart ? true : false;
          this.restartTime = building.data().settings.restart
            ? num2time(building.data().settings.restart)
            : null;
          this.vid = building.data().vid ? building.data().vid : "";
          this.buildingName = building.data().name ? building.data().name : "";
          this.buildingAddress = building.data().address
            ? building.data().address
            : "";
        });
    },

    inputChanged() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },

    async saveRestart() {
      try {
        if (this.restartTime === null) {
          this.snackbar.visible = true;
          this.snackbar.text = "Ingrese el horario deseado del reinicio ...";
          this.snackbar.color = "blue";
        } else {
          this.snackbar.visible = true;
          this.snackbar.text = "Guardando datos de reinicio ...";
          this.snackbar.color = "success";

          await db
            .doc("buildings/" + this.user.data.building)
            .update({
              "settings.restart": this.restartEnabled
                ? time2num(this.restartTime)
                : null,
            })
            .then(() => {
              //
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async saveDoors() {
      try {
        if (this.isValid) {
          this.snackbar.visible = true;
          this.snackbar.text = "Guardando accesos ...";
          this.snackbar.color = "success";

          await db
            .doc("buildings/" + this.user.data.building)
            .update(
              {
                "settings.door1_name": this.doors[0].name,
                "settings.door1_number": this.doors[0].number,
                "settings.door1_time": this.doors[0].time,
                "settings.door1_dvcid": this.doors[0].device,
                "settings.door1_nc": this.doors[0].nc,
                "settings.door2_name": this.doors[1].name,
                "settings.door2_number": this.doors[1].number,
                "settings.door2_time": this.doors[1].time,
                "settings.door2_dvcid": this.doors[1].device,
                "settings.door2_nc": this.doors[1].nc,
                "settings.door3_name": this.doors[2].name,
                "settings.door3_number": this.doors[2].number,
                "settings.door3_time": this.doors[2].time,
                "settings.door3_dvcid": this.doors[2].device,
                "settings.door3_nc": this.doors[2].nc,
              },
              { merge: true }
            )
            .then(() => {
              //
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    activeTab(key) {
      if (key == "qrTab") {
        this.qrGenerate();
      }
    },

    qrGenerate() {
      // Build titles.
      let title = "";
      let subtitle = " - " + this.whiteLabel.labels.website + " - ";
      if (!this.includeBuilding) {
        title = "TIMBRE QR";
      } else {
        if (!this.buildingName) {
          title = "TIMBRE QR | " + this.buildingAddress;
        } else {
          title = "TIMBRE QR | " + this.buildingName;
        }
      }
      // Options
      var options = {
        logo: this.whiteLabel.images.iso, // Relative address, relative to `easy.qrcode.min.js`
        logoBackgroundTransparent: false,
        logoBackgroundColor: this.colorLight,
        text: "https://" + this.whiteLabel.domains.virtual + "/building/" + this.vid,
        width: 380,
        height: 380,
        colorDark: invertColor(this.colorLight),
        colorLight: this.colorLight,
        correctLevel: QRCode.CorrectLevel.H,
        dotScale: this.dotScale / 10,
        dotScaleTiming_V: this.dotScale / 10,
        dotScaleTiming_H: this.dotScale / 10,
        dotScaleAO: this.dotScale / 10,
        dotScaleAI: this.dotScale / 10,
        quietZone: 60,
        quietZoneColor: "transparent",
        title: title,
        titleFont: "bold 22px Arial",
        titleColor: "#000000",
        titleBackgroundColor: this.colorLight,
        titleHeight: 40,
        titleTop: 0,
        subTitle: subtitle,
        subTitleFont: "20px Arial",
        subTitleColor: "#000000",
        subTitleTop: 460,
        PO: "#000000", //adjustColor(invertColor(this.colorLight), -50), // Global Posotion Outer color. if not set, the defaut is `colorDark`
        PI: "#000000", //adjustColor(invertColor(this.colorLight), -50), // Global Posotion Inner color. if not set, the defaut is `colorDark`
        AI: invertColor(this.colorLight),
        drawer: "canvas",
        crossOrigin: "anonymous",
      };
      // Create new QRCode Object
      if (this.qr) {
        this.qr.clear();
      }
      // Set a minimal timeout to give some time the element to render...
      // before assigning QRCode to the referenced element.
      setTimeout(() => {
        this.qr = new QRCode(this.$refs.qrcode, options);
      }, 200);
    },

    qrDownload() {
      this.snackbar.visible = true;
      this.snackbar.text = "Descargando " + this.whiteLabel.labels.brand + "QR" + ".png ...";
      this.snackbar.color = "success";
      this.qr.download(this.whiteLabel.labels.brand + "QR"); // File name.
    },

    colorPallete() {
      const baseColors = [
        "#E57373FF",
        "#F06292FF",
        "#BA68C8FF",
        "#9575CDFF",
        "#7986CBFF",
        "#64B5F6FF",
        "#4FC3F7FF",
        "#4DD0E1FF",
        "#4DB6ACFF",
        "#81C784FF",
        "#AED581FF",
        "#DCE775FF",
        "#FFF176FF",
        "#FFD54FFF",
        "#FFB74DFF",
        "#FF8A65FF",
        "#A1887FFF",
        "#90A4AEFF",
        "#E0E0E0FF",
      ];
      const numColors = 3; // Number of lighter colors to generate
      const increment = 5; // Increment in lightness (percentage)
      let lighterColors = [];
      baseColors.forEach((baseColor) => {
        lighterColors = generateLighterColors(baseColor, numColors, increment);
        this.swatches.push(lighterColors);
      });
    },
  },
};

function num2time(num) {
  var hours = Math.floor(num / 100);
  var minutes = num % 100;
  return hours + ":" + minutes;
}

function time2num(time) {
  if (time === null) return null;
  var hours = parseInt(time.split(":")[0]);
  var minutes = parseInt(time.split(":")[1]);
  return hours * 100 + minutes;
}

function invertColor(hex) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}
/*
function adjustColor(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}
*/
function generateLighterColors(baseColor, numColors, increment = 10) {
  // Convert baseColor to HSL
  let hslBaseColor = hexToHsl(baseColor);
  const lighterColors = [];

  // Generate lighter colors by adjusting lightness
  for (let i = 1; i <= numColors; i++) {
    // Increment the lightness component
    hslBaseColor[2] += increment / 100;
    if (hslBaseColor[2] > 1) hslBaseColor[2] = 1; // Ensure lightness doesn't exceed 1

    // Convert back to hex
    const hexColor = hslToHex(hslBaseColor);
    lighterColors.push(hexColor);
  }

  return lighterColors;
}

// Function to convert hexadecimal color to HSL
function hexToHsl(hex) {
  let r = parseInt(hex.substring(1, 3), 16) / 255;
  let g = parseInt(hex.substring(3, 5), 16) / 255;
  let b = parseInt(hex.substring(5, 7), 16) / 255;

  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

// Function to convert HSL color to hexadecimal
function hslToHex(hsl) {
  let [h, s, l] = hsl;
  let r, g, b;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return `#${Math.round(r * 255).toString(16)}${Math.round(g * 255).toString(
    16
  )}${Math.round(b * 255).toString(16)}`;
}
</script>

<style lang="scss" scoped></style>
